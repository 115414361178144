export enum TABLE_TYPES {
    ALL_SHIFTS = 'all-shifts',
    MY_CLIENTS = 'my-clients',
    TIMESHEETS = 'timesheets',
    MY_TIMESHEETS = 'my-timesheets',
    COLLAPSIBLE = 'collapsible',
    ALL_CLIENTS = 'all-clients',
    ALL_SERVICES = 'all-services',
    MY_DOCUMENTS = 'my-documents',
    ALL_DOCUMENTS = 'all-documents',
    ALL_GUARDIANS = 'all-guardians',
    SERVICE_ROLES = 'service-roles',
    ALL_THERAPISTS = 'all-therapists',
    BILLING_REPORTS = 'billing-report',
    ADMIN_USERS_LIST = 'admin-users-list',
    FREQUENCY_REPORT = 'frequency-report',
    AVAILABLE_CLIENTS = 'available-clients',
    TIMESHEET_DETAILS = 'timesheet-details',
    DETAILED_CALCULATION = 'detailed-calculation',
    MY_DETAILED_CALCULATION = 'my-detailed-calculation',
    INITIAL_VISITS_REPORTS = 'initial-visits-report',
    PERFORMANCE_REPORTS = 'performance-report',
  }