import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { Colors, DOCUMENT_STATUS_TYPES, SERVICE_TYPES, SHIFT_STATUS_TYPES } from '../enums';
import { STATES_TYPES } from './states';

export const USER_ROLES_COLORS: { [key: string]: Colors } = {
  [ROLE_TYPES.Admin]: Colors.gray,
  [ROLE_TYPES['Super admin']]: Colors.gray,
  [ROLE_TYPES.Coordinator]: Colors.gray,
  [ROLE_TYPES['Assistant Limited']]: Colors.yellow,
  [ROLE_TYPES.Assistant]: Colors.rose,
  [ROLE_TYPES.Therapist]: Colors.pink,
  [ROLE_TYPES.Guardian]: Colors.gray,
  [ROLE_TYPES['Therapist Limited']]: Colors['red-orange'],
};

export const DOCUMENT_STATUS_COLORS: { [key: string]: Colors } = {
  [DOCUMENT_STATUS_TYPES.Completed]: Colors.teal,
  [DOCUMENT_STATUS_TYPES['Report due']]: Colors.zinc,
  [DOCUMENT_STATUS_TYPES['Notes due']]: Colors.cyan,
  [DOCUMENT_STATUS_TYPES.Prepared]: Colors.neutral,
  [DOCUMENT_STATUS_TYPES.Pending]: Colors['orange-light'],
  [DOCUMENT_STATUS_TYPES.Submitted]: Colors.slate,
};

export const SPECIALITY_COLORS = {
  OT: Colors.green,
  PT: Colors.blue,
  ST: Colors.orange,
  CDS: Colors.purple,
  FSS: Colors.red,
  INT: Colors.red,
};

export const SHIFT_STATUS_COLORS = {
  [SHIFT_STATUS_TYPES.PENDING]: Colors['orange-coral'],
  [SHIFT_STATUS_TYPES.CANCELED]: Colors['zinc-light'],
  [SHIFT_STATUS_TYPES.COMPLETED]: Colors.teal,
  [SHIFT_STATUS_TYPES.DRAFT]: Colors['slate-astral'],
  [SHIFT_STATUS_TYPES.NO_SHOW]: Colors.neutral,
  [SHIFT_STATUS_TYPES.SCHEDULED]: Colors['lime-olive'],
};

export const SERVICES_STATUS_COLORS = {
  [STATES_TYPES.CLIENT_AVAILABLE]: Colors.lime,
  [STATES_TYPES.CONTACTING_PARENTS]: Colors.amber,
  [STATES_TYPES.PENDING_APPROVAL]: Colors.stone,
  [STATES_TYPES.THERAPIST_ASSIGNED]: Colors['red-pink'],
  [STATES_TYPES.TERMINATED]: Colors['slate-strong'],
  [STATES_TYPES.REJECTED]: Colors.red,
  [STATES_TYPES.ON_HOLD]: Colors['slate-light'],
};

export const SERVICES_TYPE_COLORS = {
  [SERVICE_TYPES.Assessment]: Colors.zinc,
  [SERVICE_TYPES.Ongoing]: Colors['slate-green'],
};

export const TIMESHEET_STATUS_COLORS = {
  pending: Colors.orange,
  approved: Colors.stone,
};
