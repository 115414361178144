import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from '@/lib/dayjsConfig';
import { Colors, SPECIALITY_CODES } from '@/common/enums';
import { MONTHS, SPECIALITY_CODES_ARRAY, SPECIALITY_COLORS } from '@/common/constants';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Formats a file size in human-readable form.
 *
 * @param bytes The number of bytes to format
 * @param decimalPoint The number of decimal places to display (default is 2)
 * @returns A string representation of the file size, e.g., "12.34 KB"
 */
export function formatFileSize(bytes: number, decimalPoint: number): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const isSafari = (): boolean => {
  if (typeof navigator === 'undefined') return false; // Ensure it's client-side
  const userAgent = navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

/**
 * Converts minutes into a human-readable time string.
 *
 * @param {number} minutes - The time in minutes.
 * @returns {string} The formatted time string.
 */
export function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);

  // Pad hours and minutes with leading zeros if needed
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(mins).padStart(2, '0');

  return `${formattedHours}h ${formattedMinutes}m`;
}

/**
 * Converts hours into a human-readable format, including hours and minutes.
 *
 * @param {number} hours - The time in hours.
 * @returns {string} The formatted time string.
 */
export function convertHoursToTime(hours: number): string {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h}h ${m.toString().padStart(2, '0')}m`;
}

/**
 * Formats a number with scientific notation (e.g., K, M, B) to make it more readable.
 *
 * @param {any} num - The number to format.
 * @returns {string} The formatted number string.
 */
export function shortNumber(num: any): string {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
}

/**
 * Converts a date in the "MM.DD.YYYY" format to the "YYYY-MM-DD" format used by APIs.
 *
 * @param {string} [date] - The date to convert (optional).
 * @returns {string} The formatted API date string.
 */
export function transformViewDateToApiDate(date?: string): string {
  return date ? dayjs.utc(date, 'MM.DD.YYYY').format('YYYY-MM-DD') : '';
}

/**
 * Converts a date in the "YYYY-MM-DD" format used by APIs to the "MM.DD.YYYY" format.
 *
 * @param {string} [date] - The date to convert (optional).
 * @returns {string} The formatted view date string.
 */
export function transformApiDateToViewDate(date?: string): string {
  return date ? dayjs.utc(date, 'YYYY-MM-DD').format('MM.DD.YYYY') : '';
}

/**
 * Converts a date into a human-readable format (e.g., MM/DD/YYYY).
 *
 * @param {string} [date] - The date to convert (optional).
 * @param {boolean} [isUTC=true] - Whether the input date is in UTC.
 * @returns {string} The formatted view date string.
 */
export function transformDateToViewDate(date?: string, isUTC: boolean = true): string {
  try {
    if (date && dayjs(date).isValid()) {
      if (isUTC) {
        return dayjs.utc(date).format('MM.DD.YYYY');
      } else {
        return dayjs(date).format('MM.DD.YYYY');
      }
    }
    return '';
  } catch (error) {
    return '';
  }
}

/**
 * Converts a date and time into a human-readable format (e.g., h:mm A).
 *
 * @param {string} [date] - The date and time to convert (optional).
 * @returns {string} The formatted time string.
 */
export function transformDateToTime(date?: string): string {
  try {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format('h:mm A');
      // return dayjs(date).utcOffset(dayjs().format('Z')).format('h:mm A');
    }
    return '';
  } catch (error) {
    return '';
  }
}

/**
 * Opens an address in Google Maps.
 *
 * @param {string} [address] - The address to open (optional).
 */
export function openAddressInGoogleMaps(value?: {address: string; lat?: number; lng?: number}): void {
  if (value) {
    let link = '';
    if (value.address && !(value.lat || value.lng)) {
      link = `https://www.google.com/maps/search/?api=1&query=${value.address}`;
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      link = `https://www.google.com/maps/search/?api=1&query=${value.lat},${value.lng}`;

    }
    window.open(link, '_blank', 'noopener,noreferrer');
  }
}

/**
 * Generates a list of time slots, spaced 5 minutes apart, starting from a given hour.
 *
 * @param {number} [startHours=0] - The starting hour (optional).
 * @returns {string[]} A list of formatted time strings.
 */
export function generateTimeSlots(startHours = 0): string[] {
  const times: string[] = [];
  let currentDate = new Date();
  currentDate.setHours(startHours, 0, 0, 0); // Start at night

  for (let i = 0; i < 24 * 60; i += 5) {
    // 24h, each step - 5m
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // h:mm a
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes.toString().padStart(2, '0')} ${period}`;
    times.push(formattedTime);

    // Increase at 5m
    currentDate.setMinutes(currentDate.getMinutes() + 5);
  }

  return times;
}

/**
 * Extracts the first part of a text string (i.e., everything before the first whitespace character).
 *
 * @param {string} text - The text to process.
 * @returns {string|null} The extracted text or null if no match found.
 */
export function extractCodePart(text: string): string | null {
  const foundCode = SPECIALITY_CODES_ARRAY.find(code => text.includes(code)) || null;
  return foundCode ? foundCode : null;
}

/**
 * Determines the color to use for a service role based on its title or code.
 *
 * @param {string} [title] - The service role title (optional).
 * @param {SPECIALITY_CODES} [code] - The service role code (optional).
 * @returns {Colors} The determined color.
 */
export function getColorForServiceRole(title?: string, code?: SPECIALITY_CODES): Colors {
  const _code = code ? code : title ? (extractCodePart(title) as SPECIALITY_CODES) : null;
  return _code ? (SPECIALITY_COLORS[_code] ? SPECIALITY_COLORS[_code] : Colors.green) : Colors.green;
}

/**
 * Combines a person's first and last names, along with any deactivated status, into a single string.
 *
 * @param {T} [value] - The person object to process (optional).
 * @returns {string} The formatted full name string.
 */
export function compileFullName<
  T extends {
    firstName: string;
    lastName: string;
    deactivatedAt?: string;
  },
>(value?: T): string {
  if (!value) {
    return '---';
  }
  const fn = value.firstName ?? '';
  const ln = value.lastName ?? '';
  return `${fn} ${ln} ${value.deactivatedAt ? '(Deleted)' : ''}`;
}

/**
 * Combines a person's last name with any deactivated status into a single string.
 *
 * @param {T} [value] - The person object to process (optional).
 * @returns {string} The formatted last name string.
 */
export function compileLastName<
  T extends {
    lastName: string;
    deactivatedAt?: string;
  },
>(value?: T): string {
  if (!value) {
    return '---';
  }
  const ln = value.lastName ?? '';
  return `${ln} ${value.deactivatedAt ? '(Deleted)' : ''}`;
}

/**
 * Generates a list of the last 6 months (e.g., January 2023, December 2022, etc.).
 *
 * @returns {string[]} A list of formatted month strings.
 */
export function generateLast6Months(): string[] {
  const months = Object.keys(MONTHS);
  const result: string[] = [];
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);

  for (let i = 0; i < 6; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
    const monthName = months[date.getMonth()];
    const year = date.getFullYear();
    result.push(`${monthName} (${year})`);
  }

  return result;
}

/**
 * Parses a month and year string in the format "Month (YYYY)" and returns the corresponding date.
 *
 * @param {string} value - The input string to parse.
 * @returns {string} The parsed date string.
 */
export function parseMonthYear(value: string): string {
  const match = value.match(/^([a-zA-Z]+) \((\d{4})\)$/);
  if (!match) {
    throw new Error("Invalid format. Expected 'Month (YYYY)'.");
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, month, year] = match;
  const monthNumber = (MONTHS as { [index: string]: number })[month];

  if (!monthNumber) {
    throw new Error('Invalid month name.');
  }
  return `${year}-${monthNumber}`;
}

/**
 * Gets the previous month from the current local date.
 *
 * @returns {string} The formatted previous month string.
 */
export function getPreviousMonthFromLocalDate(): string {
  const currentDate = new Date();
  const date = new Date(currentDate.getFullYear(), currentDate.getMonth());

  date.setMonth(date.getMonth() - 1);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}-${month}`;
}
